import PDPVirtualCart from './PDPVirtualCart';
import {cleanPrice, createElementFromHTML, numberThousandSeparator, qs, simulateClick} from '../../utils';
import store from '../Sticky/store/index.js';

import MonthlyTariffPrice from '../Sticky/components/monthlyTariffPrice';
import TariffName from '../Sticky/components/tariffName';
import TariffProperties from '../Sticky/components/tariffProperties';
import MonthlyDevicePrice from '../Sticky/components/monthlyDevicePrice';
import StickyPromoHeader from '../Sticky/components/stickyPromoHeader';
import ParticipationDevice from '../Sticky/components/participationDevice';
import DevicePrice from '../Sticky/components/devicePrice';
import PaymentTypeLabel from '../Sticky/components/paymentTypeLabel';
import TotalMonthly from '../Sticky/components/totalMonthly';
import Wrapper from '../Sticky/components/wrapper';
import TotalParticipation from '../Sticky/components/totalParticipation';
import InsurancePriceMonthly from '../Sticky/components/insurancePriceMonthly';
import InsurancePriceMonthlyNoDiscount from '../Sticky/components/insurancePriceMonthlyNoDiscount';
import ParticipationRangeInput from '../Sticky/components/participationRangeInput';
import AccessoriesAllPrice from '../Sticky/components/accessoriesAllPrice';
import AccessoriesList from '../Sticky/components/accessoriesList';
import AccessoriesCount from '../Sticky/components/accessoriesCount';
import TariffCommunicationPromo from '../Sticky/components/tariffCommunicationPromo';

/**
 * PDPHandler
 */
class PDPHandler {
    constructor(tariffMonthly, isPrice1RSD, deviceCode, isAccessory = false, isGadget = false, isTvOrTablet = false) {

        const urlParams = new URLSearchParams(window.location.search);
        const paramSelectedPayment = urlParams.get('selectedPayment');
        let selectedPaymentToSet;

        if(paramSelectedPayment === null){
            selectedPaymentToSet = 'rate';
        } else{
            selectedPaymentToSet = paramSelectedPayment;
        }

        this._selectedPayment = selectedPaymentToSet;

        this._instalments = 24;
        this._isActive = false;
        this._deviceCode = deviceCode;
        this._isSimulatedClickTariff = false;
        this._isAccessory = isAccessory;
        this._isGadget = isGadget;
        this._languageConsts = {};
        this._isBusiness = false;
        this._tariffCommunicationPromoList = [];
        this._isBusinessAndTablet = false;
        this._xmass2021 = false;

        this._isTvOrTablet = isTvOrTablet;

        const cleanTariffPrice = cleanPrice(tariffMonthly);

        this.state = {
            deviceMonthlyFee: null,
            deviceParticipation: null,
            tariffMonthlyFee: cleanTariffPrice,
            accesoryMonthlyFee: null,
            accessoryParticipation: null,
            insuranceMonthlyFee: null,
            isPrice1RSD: isPrice1RSD,
        }
    }

    setXmass2021 = (xmass2021) => {
      this._xmass2021 = xmass2021;
    };

    setTariffCommunicationPromoList = (val) => this._tariffCommunicationPromoList = val;
    getTariffCommunicationPromoList = () => this._tariffCommunicationPromoList ;

    isBusiness = () => this._isBusiness;

    setIsBusiness = isBusiness => this._isBusiness = isBusiness;

    setIsBusinessAndTablet = (isBusinessAndTablet) => this._isBusinessAndTablet = isBusinessAndTablet;

    setLangObj = (languageConsts) => this._languageConsts = languageConsts;

    handleIsSelected = () => {

    }

    handleColor = (inputName, setColorCodeInSliderCallback) => {
        const pdpvc = new PDPVirtualCart();
        document.querySelectorAll(`input[name="${inputName}"]`).forEach((item, key) => {
            var selectedDeviceColorEl = document.querySelector('.selected-device-color');
            if (key === 0) {
                item.checked = true;
                if (selectedDeviceColorEl) {
                    selectedDeviceColorEl.style.background = item.getAttribute('data-hex-code');
                    pdpvc.setDeviceColor(item.getAttribute('data-hex-code'), item.value);
                }
            }
            item.onclick = function () {
                if (selectedDeviceColorEl) {
                    selectedDeviceColorEl.style.background = this.getAttribute('data-hex-code');
                    pdpvc.setDeviceColor(this.getAttribute('data-hex-code'), this.value);

                    setColorCodeInSliderCallback(this.value);
                    setDLPDP();

                }
            }
        });
    };

    cashInstalmentsSwitch = (value) => {
        if (value) {
            this._selectedPayment = value;
        }

        const installmentsTypeContent = document.querySelector('.installments_type_content');

        if (!installmentsTypeContent) {
            return;
        }


        if (this._selectedPayment === 'gotovina') {
            this.state.accessoryParticipation = this.getAccessoriesCashPriceFromAllItems()

            // qs('.acc-all-price', accAllPriceEl => {
            //     const cashPrice = accAllPriceEl.getAttribute('data-price-cash');
            //     accAllPriceEl.innerHTML = `${numberThousandSeparator(cashPrice)} RSD`; // eslint-disable-line no-undef
            // });

            qs('.accessory-item-price-single', accItemPriceSingleEl => {
                const itemPriceCash = accItemPriceSingleEl.getAttribute('data-price-cash');
                if (itemPriceCash) {
                    accItemPriceSingleEl.innerHTML = `${numberThousandSeparator(itemPriceCash)} RSD`; // eslint-disable-line no-undef
                }
            });

            qs('.acc-price-item', accPriceItem => {
                const priceCash = accPriceItem.getAttribute('data-acc-price-cash');
                accPriceItem.innerHTML = `${numberThousandSeparator(priceCash)} RSD`;// eslint-disable-line no-undef
            });
        } else if(this._selectedPayment === 'rate') {
            this.reRenderAccMonthlyPrices();

            if (this._isTvOrTablet) {
                const placanjeRadioInput = document.querySelector('#placanje-rate-mobile');
                if (placanjeRadioInput) {
                    placanjeRadioInput.checked = true;
                }
            }

        } else {
            throw new Error('wrong payment type');
        }
        this.setTotal();
    }

    getAccessoriesCashPriceFromAllItems = () => {
        const pdpvc = new PDPVirtualCart();
        const accessories = pdpvc.getAccessories();
        if (!accessories) {
            return 0;
        }
        return accessories.reduce((accumulator, currentValue) => {
            return parseInt(accumulator)+ parseInt(currentValue.priceCash);
        }, 0);
    }

    reRenderAccMonthlyPrices = () => {
        const pdpvc = new PDPVirtualCart();

        this.setAccessoriesAllPrice(pdpvc.getAccessories());

        qs('.accessory-item-price-single', accItemPriceSingleEl => {
            const itemPrice = accItemPriceSingleEl.getAttribute('data-price');
            const priceMonthly = Math.round(parseInt(itemPrice) / this._instalments);
            if (priceMonthly) {
                accItemPriceSingleEl.innerHTML = `${numberThousandSeparator(priceMonthly)} RSD/mes`; // eslint-disable-line no-undef
            }
        });
        qs('.acc-price-item', accPriceItem => {
            const price = accPriceItem.getAttribute('data-acc-price');
            const priceToShow = Math.round(parseInt(price) / this._instalments);
            accPriceItem.innerHTML = `${numberThousandSeparator(priceToShow)} RSD/mes`; // eslint-disable-line no-undef
        });
    }

    getDevicePriceMonthly = () => {
        const { tariff, instalments, participation } = store.state;
        const { price } = tariff;

        return Math.round((cleanPrice(price) - participation) / instalments);
    }

    // isPaymentCash = () => ;
    _handlePaymentRadioClick = (e) => {
        store.dispatch('setPaymentType', e.target.value);
        //console.log('usao u _handlePaymentRadioClick')

        if(this._xmass2021){
            const { tariff } = store.state;

            if(tariff.tariffOldPrice !== '0'){
                //console.log('usao u _handlePaymentRadioClick tariffOldPrice nije 0')
                if(e.target.value === 'gotovina'){
                    if (tariff.tariffCode !== 'BPO_NEO24_P') {
                        qs('#old_price_cash_promo', oldPriceCashPromo => {
                            oldPriceCashPromo.classList.remove('display_none');
                        });
                        // qs('#old_price_promo', oldPricePromo => {
                        //     oldPricePromo.classList.add('display_none');
                        // });
                    }
                } else if(e.target.value === 'rate'){
                    if (tariff.tariffCode !== 'BPO_NEO24_P') {
                    qs('#old_price_cash_promo', oldPriceCashPromo => {
                        oldPriceCashPromo.classList.add('display_none');
                    });
                    qs('#old_price_promo', oldPricePromo => {
                        oldPricePromo.classList.remove('display_none');
                    });
                    }
                }
            }
        }
        setDLPDP();

    };

    handlePaymentType = (inputName) => {
        const { _handlePaymentRadioClick } = this;
        qs(`input[name="${inputName}"]`, item => {
            item.onclick = _handlePaymentRadioClick;
        });
        qs(`input[name="${inputName}-mobile"]`, item => {
            item.onclick = _handlePaymentRadioClick;
        });
    };

    createNewAccessoryNode = (accCode, accComercialName, accPrice, accPriceCash) => {

        let priceToShow = numberThousandSeparator(accPrice); // eslint-disable-line no-undef

        let showMonthlyString = '';
        if (this._selectedPayment === 'rate') {
            priceToShow = numberThousandSeparator(parseInt(accPrice / this._instalments)); // eslint-disable-line no-undef
            showMonthlyString = '/mes'
        }

        return createElementFromHTML(`
            <div class="m-bar-structure acc-item-${accCode}" >
                <div class="m-bar-structure__left">
                    <div class="m-bar-structure__main">
                        <div class="m-bar-structure__image">
                            <a href="#" class="a-image remove-acc-item-${accCode}" style="width:10px;height:10px;" data-acc-code="${accCode}" >
                                <img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-x-red.svg" alt="" />
                            </a>
                        </div>
                        <div class="m-bar-structure__text">
                            <span  class="a-01-text__primary-light--sm "  >${accComercialName}</span>

                        </div>
                    </div>
                </div>
                <div class="m-bar-structure__right">
                    <div class="m-bar-structure__main">
                        <div class="m-bar-structure__text">
                            <span  class="a-01-text__primary-bold--sm accessory-item-price-single" data-price="${accPrice}" data-price-cash="${accPriceCash}"  >${priceToShow} RSD${showMonthlyString}</span> <!-- todo broj rata x iznos rate (24 x 290 RSD) -->
                        </div>
                    </div>
                </div>
            </div>`
            );
    };
    removeAccessoryNode = (accCode) => {
        const pdpvc = new PDPVirtualCart();

        pdpvc.removeAccesory(accCode);
        qs(`.acc-item-${accCode}`, itemToDelete => {
            itemToDelete.remove();
        });
    };
    setAccessoriesAllPrice = (accesories) => {
        if (!accesories) {
            return null;
        }
        // let allAccPrice = 0;
        // let allAccPriceCash = 0;
        // accesories.forEach(acc => {
        //     allAccPrice += acc.hasOwnProperty('accessoryPrice') ? parseInt(acc.accessoryPrice) : 0;
        //     allAccPriceCash += acc.hasOwnProperty('priceCash') ? parseInt(acc.priceCash) : 0;
        // });
        // if (this._selectedPayment === 'rate') {
        //     this.state.accesoryMonthlyFee = allAccPrice / this._instalments;
        // } else {
        //     this.state.accesoryMonthlyFee = 0;
        //     this.state.accessoryParticipation = this.getAccessoriesCashPriceFromAllItems();
        // }

        // this.setTotal();

        // qs('.acc-all-price', accAllPriceEl => {
        //
        //     let priceToShow = allAccPriceCash;
        //     let monthlyPrice = 0;
        //     let stringMonthlyPartOfLabel = '';
        //     if (this._selectedPayment === 'rate') {
        //         monthlyPrice = Math.round(allAccPrice / this._instalments);
        //         stringMonthlyPartOfLabel = '/mes';
        //         priceToShow = monthlyPrice;
        //     }
        //
        //
        //     accAllPriceEl.setAttribute('data-price', allAccPrice);
        //     accAllPriceEl.setAttribute('data-price-cash', allAccPriceCash);
        //     accAllPriceEl.innerHTML = `${numberThousandSeparator(priceToShow)} RSD${stringMonthlyPartOfLabel}`; // eslint-disable-line no-undef
        // });
    };

    accessoriesEmptyStateToggle = () => {
        // qs('.selected-accessories', selectedAccessoriesEl => {
        //     if (selectedAccessoriesEl.classList.contains('display_none')) {
        //         selectedAccessoriesEl.classList.remove('display_none');
        //     } else {
        //         selectedAccessoriesEl.classList.add('display_none');
        //     }
        // });
        // qs('.not-selected-accessories', notSelectedAccessoriesEl => {
        //     if (notSelectedAccessoriesEl.classList.contains('display_none')) {
        //         notSelectedAccessoriesEl.classList.remove('display_none')
        //     } else {
        //         notSelectedAccessoriesEl.classList.add('display_none');
        //     }
        // });
    }
    accessoriesEmptyStateSwitch = (isEmpty) => {
        // if (isEmpty) {
        //     qs('.selected-accessories', selectedAccessoriesEl => {
        //         selectedAccessoriesEl.classList.add('display_none');
        //     });
        //     qs('.not-selected-accessories', notSelectedAccessoriesEl => {
        //         notSelectedAccessoriesEl.classList.remove('display_none')
        //     });
        // } else {
        //     qs('.selected-accessories', selectedAccessoriesEl => {
        //         selectedAccessoriesEl.classList.remove('display_none');
        //     });
        //     qs('.not-selected-accessories', notSelectedAccessoriesEl => {
        //         notSelectedAccessoriesEl.classList.add('display_none')
        //     });
        // }

    }
    accessoriesEmptyStateHandle = () => {
        const pdpvc = new PDPVirtualCart();
        const { accessories } = pdpvc.getAll();

        this.accessoriesEmptyStateSwitch(accessories.length === 0);
    }

    toggleAccessoryBasketSectionIfEmpty = () => {
        // const { accessories } = store.state;
        //
        // qs('.tariff-accessories-close-button-clickable', el => {
        //     if (accessories.length === 0) {
        //         if (el.classList.contains('expanded')) {
        //             this.closeAccessoriesBasket();
        //         }
        //     } else {
        //         if (!el.classList.contains('expanded')) {
        //             this.closeAccessoriesBasket();
        //         }
        //     }
        // });
    }

    handleAccessoryAdd = (addButtonClass, addLabel, removeLabel) => {
        // const pdpvc = new PDPVirtualCart();
        const { toggleAccessoryBasketSectionIfEmpty } = this;
        const accessoriesSection = document.querySelectorAll('.accessories-section');

        if (accessoriesSection.length) {
            qs('.not-selected-accessories', scrollToAccessoriesEl => {
                scrollToAccessoriesEl.onclick = e => {
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
            qs('.scroll-to-accessories', scrollToAccEl => {
                scrollToAccEl.onclick = e => {
                    $('html, body').animate({ // eslint-disable-line no-undef
                        scrollTop: parseInt($(".accessories-section").offset().top) // eslint-disable-line no-undef
                    }, 1000);
                    e.preventDefault();
                }

            });
            qs(`.${addButtonClass}`, addButton => {
                addButton.onclick = (e) => {
                    const accId = e.currentTarget.getAttribute('data-acc-id');
                    const accCode = e.currentTarget.getAttribute('data-acc-code');
                    const accComercialName = e.currentTarget.getAttribute('data-acc-comerical-name');
                    const accName = e.currentTarget.getAttribute('data-acc-name');
                    const accManufacturerName = e.currentTarget.getAttribute('data-acc-manufacturer-name');
                    const accPrice = e.currentTarget.getAttribute('data-acc-price');
                    const accPriceCash = e.currentTarget.getAttribute('data-acc-price-cash');
                    const accImage = e.currentTarget.getAttribute('data-acc-image');
                    const accDeviceType = e.currentTarget.getAttribute('data-acc-device-type');



                    // var mmids = []
                    // mmids.push(accCode)
                    //
                    // window.loader = false
                    // $(e.currentTarget).addClass('loading');
                    // $(e.currentTarget).attr('disabled', true);
                    // $(e.currentTarget).find('.a-02-button__label').html(
                    // // $('.add-to-cart .a-02-button__label').html(
                    //     `<div class="cp_spinner_wrapper">
                    //         Molimo Vas sačekajte
                    //         <span class="cp_spinner cp_round"></span>
                    //     </div>`
                    // )
                    //
                    // this.checkAvailabilityPdp(mmids, e.currentTarget).then(response => {
                    // })

                    qs('.accessory-items-wrapper', el => {

                        const itemExist = !!document.querySelector(`.acc-item-${accCode}`);

                        if (!itemExist) {
                            store.dispatch('appendAccessory', {
                                accId,
                                code: accCode,
                                commercialName: accComercialName,
                                manufacturerName: accManufacturerName,
                                name: accName,
                                price: accPrice,
                                priceCash: accPriceCash,
                                image: accImage,
                                deviceType: accDeviceType
                            });
                            const { accessories } = store.state
                        } else {
                            store.dispatch('removeAccessory', {code: accCode});
                        }

                        this.toggleButton(e.currentTarget, addLabel, removeLabel);
                        toggleAccessoryBasketSectionIfEmpty();

                    });
                    e.preventDefault();
                };
            });
        } else {
            // qs('.tariff-accessories-close-button-clickable', accStickySection => {
            //     accStickySection.classList.add('display_none');
            // });
        }
    };

    getCurrentAccessory = () => {
        const pdpvc = new PDPVirtualCart();
        const { accessories, device } = pdpvc.getAll();
        if (accessories.length) {
            const currentAccArr = accessories.filter(acc => acc.accessoryCode === device.deviceCode);
            const currentAcc = currentAccArr.length ? currentAccArr[0] : null;
            if (currentAcc) {
                return currentAcc;
            }
        }
        return null;
    }
    getCurrentGadget = () => {
        const pdpvc = new PDPVirtualCart();
        const { gadgets, device } = pdpvc.getAll();
        if (gadgets.length) {
            const currentAccArr = gadgets.filter(acc => acc.accessoryCode === device.deviceCode);
            const currentAcc = currentAccArr.length ? currentAccArr[0] : null;
            if (currentAcc) {
                return currentAcc;
            }
        }
        return null;
    }

    handleInstalments = (installmentsSelectName) => {

        const { state, setTotal, reRenderAccMonthlyPrices, reRenderTariffModalPrices, reRenderAndSetValuesForRange, _isAccessory } = this;
        const pdpvc = new PDPVirtualCart();
        const participation = pdpvc.getParticipation();
        let newPriceMonthly = null;
        qs(`select[name="${installmentsSelectName}"]`, installmentsSelect => {
            // if (_isAccessory) {
            const { device } = virtualCart.getAll(); // eslint-disable-line no-undef
            const { deviceInstalments: devInstalments } = device;
            // const { maxParticipationForActivation } = tariff;

            const deviceInstalments = devInstalments || 24;
            if (deviceInstalments) {
                const option = installmentsSelect.querySelector(`option[value="${deviceInstalments}"]`);
                option.selected = true;

                const customSelectWrapper = installmentsSelect.parentElement;

                if (customSelectWrapper) {
                    const valuePlaceholder = customSelectWrapper.querySelector('.a-input-box__value');
                    if (valuePlaceholder) {
                        valuePlaceholder.innerHTML = `${deviceInstalments}`;
                    }
                }

                pdpvc.setDeviceInstalments = deviceInstalments;
                this._instalments = deviceInstalments;
                pdpvc.instalments = deviceInstalments;
                virtualCart.instalments = deviceInstalments; // eslint-disable-line no-undef

                if (!_isAccessory){
                    const { price } = pdpvc.getTariff();
                    newPriceMonthly = Math.round((cleanPrice(price) - participation) / deviceInstalments);

                    state.deviceMonthlyFee = newPriceMonthly;

                    reRenderTariffModalPrices();
                    reRenderAccMonthlyPrices();
                    reRenderAndSetValuesForRange();


                } else {
                    // const currentGadget = this._isGadget ? this.getCurrentGadget() : this.getCurrentAccessory();

                    // const { accessoryPrice } = currentGadget;
                    // const newMonthlyPrice = Math.round(cleanPrice(accessoryPrice) / deviceInstalments);
                    // qs('.device-price-tp-price', el => {
                    //     el.innerHTML = `${numberThousandSeparator(newMonthlyPrice)} bbbbb`; // eslint-disable-line no-undef
                    // });
                }
            }

            // }
            installmentsSelect.onchange = (e) => {


                store.dispatch('setInstalments', parseInt(e.currentTarget.value));



                const pdpvc = new PDPVirtualCart();
                const participation = pdpvc.getParticipation();
                const selectedValue = parseInt(e.currentTarget.value);
                pdpvc.setDeviceInstalments = selectedValue;
                this._instalments = selectedValue;
                pdpvc.instalments = selectedValue;
                virtualCart.instalments = selectedValue; // eslint-disable-line no-undef
                let newPriceMonthly = null;
                if (_isAccessory) {
                    // const currentAcc = this.getCurrentAccessory();
                    // if (currentAcc) {
                    //     const { accessoryPrice } = currentAcc;
                    //     const accCleanPrice = cleanPrice(accessoryPrice);
                    //     newPriceMonthly = Math.round((cleanPrice(accCleanPrice) - participation || 0) / selectedValue);
                    //     state.deviceMonthlyFee = newPriceMonthly;
                    // }
                } else {
                    const { price } = pdpvc.getTariff();
                    newPriceMonthly = Math.round((cleanPrice(price) - participation) / selectedValue);

                    state.deviceMonthlyFee = newPriceMonthly;


                    reRenderTariffModalPrices();
                    reRenderAccMonthlyPrices();
                    reRenderAndSetValuesForRange();
                }

                // qs('.device-price-tp-price', el => {
                //     el.innerHTML = `${numberThousandSeparator(newPriceMonthly)}`; // eslint-disable-line no-undef
                // });

                // qs('.rata-iznos', rataIznosEl => {
                //     rataIznosEl.innerHTML = `${numberThousandSeparator(newPriceMonthly)} RSD`; // eslint-disable-line no-undef
                // });


                setTotal();
            }
        });
    };
    reRenderTariffModalPrices = () => {
        // const pdpvc = new PDPVirtualCart();
        // const participation = pdpvc.getParticipation();



        qs('.tariff-modal-card-tariff-price', el => {
            const price = cleanPrice(el.getAttribute('data-price'));
            const minActivationForInstallment = cleanPrice(el.getAttribute('data-min-activation-for-installment'));
            const maxActivationForInstallment = cleanPrice(el.getAttribute('data-max-activation-for-installment'));
            const participation = cleanPrice(el.getAttribute('data-participation'));
            const oldPrice = cleanPrice(el.getAttribute('data-old-price'));

            let monthlyOld ;
            if(oldPrice){
                monthlyOld = Math.round((oldPrice - participation) / this._instalments);
            }

            let monthly = Math.round((price - participation) / this._instalments);
            let participationToShow = participation;

            // if (minActivationForInstallment > (price - participation)) {
            //     const itemsToHide = el.parentElement.parentElement.querySelectorAll('.hide-if-price-lower-than-min-for-activation');
            //     itemsToHide.forEach(itemToHide => {
            //         itemToHide.classList.add('display_none');
            //     })
            // }


            const maxInstalment = maxActivationForInstallment / this._instalments;
            const minInstalment = minActivationForInstallment / this._instalments;

            if (monthly > maxInstalment) {
                monthly = maxInstalment;
                participationToShow = price - maxActivationForInstallment;
            }

            if (monthly < minInstalment) {
                const tariffDataModalInstalmentsInfoPart =  el.closest('.price-part-of-tariff-data-modal');
                if (tariffDataModalInstalmentsInfoPart) {
                    tariffDataModalInstalmentsInfoPart.classList.add('display_none');

                    const cardParent = el.closest('.m-tariff-card');
                    if (cardParent) {
                        const lineEl = cardParent.querySelector('.price-part-of-tariff-data-modal-upper-border');
                        if (lineEl) {
                            lineEl.classList.remove('display_none');
                        }
                    }
                }
            }

            let monthlyOldDiscountHTML = ``;
            // if(this._xmass2021){
                if(monthlyOld  && monthly !== monthlyOld){
                    monthlyOldDiscountHTML = `
                        <div class="m-device-card__price-discount old-price-for-rate">
                            <span class="a-01-text__primary--xs lh-large">
                              <!--${this._instalments} x ${numberThousandSeparator(monthlyOld)} RSD/mes -->
                              ${numberThousandSeparator(monthlyOld)}
                            </span>
                        </div>
                    `;
                }
                // if(this._xmass2021){
                //     if(finalTariffOldPrice !== 0){
                //         qs('#modal-tariff-discount-price', el => {
                //             el.innerHTML = finalTariffOldPrice;
                //         });
                //     }
                // }

            // }

            if(this._isBusiness) {
                if(monthly) {
                    const strMonthly = String(monthly);
                    const match = strMonthly.match(/(\d+(\.\d+)?)/);

                    if (!match) return strMonthly;

                    const number = parseFloat(match[1]);
                    monthly = number.toFixed(0);
                }
            }

            el.innerHTML = `
                ${monthlyOldDiscountHTML}
                ${this._instalments} x ${numberThousandSeparator(monthly)} <span class="rsd-mes-rate">RSD/mes</span>`; // eslint-disable-line no-undef

            // qs('.tariff-modal-card-participation', el => {
            //     el.innerHTML = `+ ${numberThousandSeparator(participationToShow)} RSD ${this._languageConsts['now']}`; // eslint-disable-line no-undef
            // });
            const participationEl = el.parentElement.querySelector('.tariff-modal-card-participation');

            if (participationEl){
                participationEl.innerHTML = `+ ${numberThousandSeparator(participationToShow)} RSD ${this._languageConsts['now']}`; // eslint-disable-line no-undef
            }



        });

        qs('.tariff-modal-card-total', el => {
            const devicePrice = cleanPrice(el.getAttribute('data-price'));
            const tariffMonthly = cleanPrice(el.getAttribute('data-tariff-monthly'));
            const minActivationForInstalments = cleanPrice(el.getAttribute('data-min-activation-for-installment'));
            const maxActivationForInstalments = cleanPrice(el.getAttribute('data-max-activation-for-installment'));
            const participationTariff = cleanPrice(el.getAttribute('data-tariff-participation')) || cleanPrice(el.getAttribute('data-participation')) ;
            const tariffOldPrice = cleanPrice(el.getAttribute('data-tariff-old-price'));
            const oldMonthlyAllFor = cleanPrice(el.getAttribute('data-tariff-old-monthly-all-for'));
            const tariffOldPriceWithDevice = cleanPrice(el.getAttribute('data-tariff-old-with-device'));

            let monthly = Math.round((devicePrice - participationTariff) / this._instalments);

            const deviceMonthly = Math.round((devicePrice - parseInt(participationTariff)) / this._instalments);
            let monthlyTotal = deviceMonthly + tariffMonthly;

            const minInstalmentMonthly = minActivationForInstalments / this._instalments;
            const maxInstalmentMonthly = maxActivationForInstalments / this._instalments;

            if (minInstalmentMonthly === maxInstalmentMonthly && maxInstalmentMonthly === 100) {
                if (deviceMonthly < minInstalmentMonthly) {
                    monthlyTotal = minInstalmentMonthly + tariffMonthly;
                } else {
                    monthlyTotal = maxInstalmentMonthly + tariffMonthly;

                }
            }

            //const { tariffMonthlyFee } = this.state;
            const allForDiscount = Math.round(cleanPrice(tariffOldPrice) / this._instalments + cleanPrice(tariffMonthly));
            let allForDiscountHTML;
            // if(xmass2021){
                if(oldMonthlyAllFor !== 0){
                    allForDiscountHTML = `
                        <span class="m-device-card__price-discount a-01-text__primary--sm" id="modal-tariff-discount-price">${numberThousandSeparator(allForDiscount)} RSD/mes</span>  
                    `;
                } else{
                    allForDiscountHTML = `
                        <span class="m-device-card__price-discount a-01-text__primary--sm visibility_hidden" id="modal-tariff-discount-price">${numberThousandSeparator(allForDiscount)} RSD/mes</span>  
                    `;
                }
            // }
            // if(!xmass2021){
            //     allForDiscountHTMLtariffOldpriceWithdevice = ``;
            // }
            /* NEO15 FEB PROMO */
            const tariffMonthlyAndHwInstallment = monthly + tariffOldPriceWithDevice;
            if(tariffOldPriceWithDevice !== 0){
                allForDiscountHTML = `
                    <span class="m-device-card__price-discount a-01-text__primary--sm lh-large" id="modal-tariff-discount-price">${numberThousandSeparator(tariffMonthlyAndHwInstallment)}</span>  
                `;
                const separatorEl = $(el).parent().parent().prev()
                $(separatorEl).find('.tariff-special-separator-horizontal-pdp').addClass('custom-separator-pdp')
                $(separatorEl).append(allForDiscountHTML)
            }

            // qs('.special-tariff-all-for', el => {
            //     // el.innerHTML += `${allForDiscountHTML}`
            // })


            el.innerHTML = `
            ${numberThousandSeparator(monthlyTotal)}
            <span class="rsd-mes-final">RSD/mes</span>
            `; // eslint-disable-line no-undef
        });

    }

    toggleButton = (buttonEl, flatLabel = 'Dodaj', invertedLabel = 'Ukloni') => {
        if (!buttonEl) return null;
        const { className } = buttonEl;
        const currentTargetImg = buttonEl.querySelector('img');
        const label = buttonEl.querySelector('.a-02-button__label');
        if (className.indexOf('inverted') !== -1) {
            buttonEl.className = className.replace('inverted', 'flat');
            currentTargetImg.src = 'https://cdn.a1.rs/a1website/img/a1/icons/icon-chevron-right-white.svg';
            label.innerHTML = flatLabel;
        } else {
            buttonEl.className = className.replace('flat', 'inverted');
            currentTargetImg.src = 'https://cdn.a1.rs/a1website/img/a1/icons/icon-chevron-right-primary.svg';
            label.innerHTML = invertedLabel;
        }
    };

    setTariff = () => {
        // const pdpvc = new PDPVirtualCart();
        // const { toggleButton, state } = this;


    }

    handleTariffs = (tariffsClass, chooseLabel, removeLabel) => {
        // console.log('handleTarrifs')
        const pdpvc = new PDPVirtualCart();
        const { toggleButton, state } = this;


        qs(`.${tariffsClass}`, tariffSelectButton => {
            tariffSelectButton.onclick = (e) => {
                const { tariffCode } = pdpvc.getTariff();
                const deviceType = pdpvc._device.deviceType;
                const deviceCode = pdpvc._device.deviceCode;
                const tariffCodeNew = e.currentTarget.getAttribute('data-tariff-code');
                let price = e.currentTarget.getAttribute('data-price');
                let priceCash = e.currentTarget.getAttribute('data-price-cash');
                const oldPrice = e.currentTarget.getAttribute('data-old-price');
                const oldPriceCash = e.currentTarget.getAttribute('data-old-price-cash');
                const xmassDiscount = e.currentTarget.getAttribute('data-xmass-discount');
                const xmassDiscountCash = e.currentTarget.getAttribute('data-xmass-discount-cash');
                const externalId = e.currentTarget.getAttribute('data-external-id');
                const tariffname = e.currentTarget.getAttribute('data-tariff-name');
                let participation = e.currentTarget.getAttribute('data-participation');
                const name = e.currentTarget.getAttribute('data-name');
                const monthlyDevicePrice = e.currentTarget.getAttribute('data-monthly-device-price');
                const minPriceForActivation = e.currentTarget.getAttribute('data-min-activation-for-installment');
                const maxPriceForActivation = e.currentTarget.getAttribute('data-max-activation-for-installment');
                const tariffCollection = e.currentTarget.getAttribute('data-tariff-collection');

                const propertiesCSV = e.currentTarget.getAttribute('data-properties');
                const propertiesCSVWithDevice = e.currentTarget.getAttribute('data-properties-with-device');
                const propertiesCSVWithoutDevice = e.currentTarget.getAttribute('data-properties-without-device');

                const oldMonthlyDevicePrice = e.currentTarget.getAttribute('data-old-monthly-device-price');
                const shortDescription = e.currentTarget.getAttribute('data-short-description');
                var tariffShortDescriptionWithDevice = e.currentTarget.getAttribute('data-tariff-short-description-with-device');
                var tariffShortDescriptionWithoutDevice = e.currentTarget.getAttribute('data-tariff-short-description-without-device');

                /* NEO15 FEB PROMO */
                const tariffMonthlyPriceOld = e.currentTarget.getAttribute('data-tariff-monthly-price-old');
                const tariffMonthlyDevicePriceOld = e.currentTarget.getAttribute('data-tariff-monthly-device-price-old');

                let properties = [];
                if (propertiesCSV) {
                    properties = propertiesCSV.split(';').filter(prop => !!prop);
                }
                if (propertiesCSVWithDevice) {
                    let propsWithDevice = propertiesCSVWithDevice.split(';').filter(prop => !!prop)
                    propsWithDevice.forEach(prop => {
                        properties.push(prop);
                    })
                }

                if (window.isDiscount15k && !window.location.href.includes("poslovni") && deviceCode !== "TCLMT40X") {
                    const numericPriceCash = parseFloat(cleanPrice(priceCash));
                    const numericPrice = parseFloat(cleanPrice(price));
                    if(tariffCodeNew != 'STANDALONE'){
                    if (isNaN(numericPriceCash)) {
                        // console.error("Invalid priceCash value:", priceCash);
                        priceCash = 1;
                    } else if (numericPrice - 15000 <= 0) {
                        priceCash = 1;
                    } else if (priceCash !== '1') {
                        const priceBeforeDiscount = numericPriceCash ;
                        priceCash = numberThousandSeparator(priceBeforeDiscount - 15000 * 0.9);
                    }


                    price = numericPrice - 15000;

                    let fullPriceWeb2 = $('#tariffs-item-STANDALONE').data("price");
                    let fullPriceWeb  = fullPriceWeb2.toString();
                        /*console.log( typeof fullPriceWeb,'<-------- typeof fullPriceWeb1111');*/
                    let fullPrice = parseFloat(cleanPrice(fullPriceWeb));

                        /*console.log(fullPriceWeb,'<--------fullPriceWeb');
                        console.log(fullPrice,'<--------fullPrice');*/


                        if (price < maxPriceForActivation) {
                            participation = 0;
                        } else {
                            participation = price - maxPriceForActivation;
                        }
                        /*console.log(tariffCode,'<--------tariffCode');*/
                        if((tariffCodeNew === "BPO_NEO30_R" || tariffCodeNew === "BPO_NEOSUPER_R" || tariffCodeNew === "BPO_NEOULTRA_R") && fullPrice > 129999) {
                            participation = 15000;
                        }
                    }

                }
                /*console.log(participation,'<--------participation');*/

                // this.setTariff(tariffCode, price, priceCash, tariffname, name, monthlyDevicePrice, oldMonthlyDevicePrice, properties, participation, minPriceForActivation, maxPriceForActivation, null, oldMonthlyDevicePrice, oldPrice, oldPriceCash)

                store.dispatch('setTariff', {
                    externalId: externalId,
                    maxParticipationForActivation: maxPriceForActivation,
                    minParticipationForActivation: minPriceForActivation,
                    monthlyFee: monthlyDevicePrice,
                    name: name,
                    participation: participation,
                    price: price,
                    priceCash: priceCash,
                    tariffCode: tariffCodeNew,
                    tariffName: tariffname,
                    tariffProperties: properties,
                    tariffOldPrice: oldPrice,
                    tariffOldPriceCash: oldPriceCash,
                    tariffCollection,
                    oldMonthlyDevicePrice: oldMonthlyDevicePrice,
                    tariffMonthlyPriceOld: tariffMonthlyPriceOld,
                    tariffMonthlyDevicePriceOld: tariffMonthlyDevicePriceOld,
                    shortDescription: shortDescription,
                    shortDescriptionWithDevice: tariffShortDescriptionWithDevice,
                    shortDescriptionWithoutDevice: tariffShortDescriptionWithoutDevice,
                });


                if(this._xmass2021){
                    //console.log(oldPrice, "USAO SAM U XMASS2021")

                    if(oldPrice && oldPrice !== '0'){

                        const { paymentType } = store.state;

                        qs('.sticky-promo-header-desktop', el => {
                            el.classList.add('display_none')
                        });

                        if(paymentType === 'rate'){
                            qs('#old_price_promo', el => {
                                //console.log("SKLANJAM DISPLAY NONE")
                                el.classList.remove('display_none');
                            });

                        } else if(paymentType === 'gotovina'){
                            qs('#old_price_cash_promo', el => {
                                el.classList.remove('display_none');
                            });
                        }

                        qs('#bf_sticky_old_price', el => {
                            el.innerHTML = xmassDiscount;
                        });

                        qs('#bf_sticky_old_price_cash', el => {
                            el.innerHTML = xmassDiscountCash;
                        });

                    } else{

                        //console.log("XMASS JESTE 0!!!")

                        qs('#old_price_promo', el => {
                            //console.log("MORAM DA DODAM DISPLAY NONE")
                            el.classList.add('display_none');
                        });

                        qs('#old_price_cash_promo', el => {
                            el.classList.add('display_none');
                        });

                        qs('#insurancePromo', el => {
                            el.classList.remove('xmass-display_none');
                            el.classList.remove('display_none');
                        });

                    }

                }

                if(this._isBlackFriday){
                    //console.log(oldPrice, "USAO SAM U _isBlackFriday")
                    if(oldPrice !== '0'){

                        const { paymentType } = store.state;

                        qs('.sticky-promo-header-desktop', el => {
                            el.classList.add('display_none');
                        });

                        if(paymentType === 'rate'){
                            qs('#old_price_promo', el => {
                                el.classList.remove('display_none');
                            });
                        } else if(paymentType === 'gotovina'){
                            qs('#old_price_cash_promo', el => {
                                el.classList.remove('display_none');
                            });
                        }

                        qs('#bf_sticky_old_price', el => {
                            el.innerHTML = oldPrice;
                        });

                        qs('#bf_sticky_old_price_cash', el => {
                            el.innerHTML = oldPriceCash;
                        });

                    } else{

                        qs('#old_price_promo', el => {
                            el.classList.add('display_none');
                        });

                        qs('#old_price_cash_promo', el => {
                            el.classList.add('display_none');
                        });

                        qs('#insurancePromo', el => {
                            el.classList.remove('bf_promo_active');
                            el.classList.remove('display_none');
                        });

                        qs('#platinumPromo', el => {
                            el.classList.remove('display_none');
                        });

                    }

                }


                store.dispatch('setParticipation', cleanPrice(participation));

                qs('[name="ucesce"]', el => {
                    el.min = cleanPrice(participation);
                    el.value = cleanPrice(participation);
                });

                if (participation) {
                    participation = cleanPrice(participation);

                } else {
                    participation = 0;
                }
                state.tariffMonthlyFee = cleanPrice(monthlyDevicePrice);


                if (tariffCode && tariffCode !== tariffCodeNew) {
                    const oldTariffEl = document.querySelector(`a[data-tariff-code="${tariffCode}"]`);
                    const newTariffEl = document.querySelector(`a[data-tariff-code="${tariffCodeNew}"]`);

                    toggleButton(oldTariffEl, 'Izaberi', 'Izaberi');
                    toggleButton(newTariffEl, 'Izaberi', 'Izaberi');

                }

                if(tariffCodeNew === 'BPO_TV_XPLOR_M') {
                    $('#xplore-pdp').css('display', 'unset');
                    $('#others-pdp').css('display', 'none');
                } else {
                    $('#xplore-pdp').css('display', 'none');
                    $('#others-pdp').css('display', 'unset');
                }

                if (tariffCode === tariffCodeNew) {
                    // pdpvc.clearTariff();
                } else {
                    pdpvc.setTariff(tariffCodeNew, price, priceCash, externalId, tariffname, name, monthlyDevicePrice, null, properties, participation, minPriceForActivation, maxPriceForActivation, tariffCollection, oldMonthlyDevicePrice, null, null, shortDescription, tariffShortDescriptionWithDevice, tariffShortDescriptionWithoutDevice);
                }

                // qs('.participation-basket-label-value', el => {
                //     el.innerHTML = `${numberThousandSeparator(participation)}`; // eslint-disable-line no-undef
                // });

                if (tariffCollection === '5') {
                    // store.dispatch('setPaymentType', 'gotovina');
                    // this.cashInstalmentsSwitch('gotovina');
                    // qs('.basket-tariff-price', tariffPriceLabel => {
                    //     tariffPriceLabel.innerHTML = ``;
                    // });
                } else {
                    // qs('.basket-tariff-price', tariffPriceLabel => {
                    //     tariffPriceLabel.innerHTML = `${monthlyDevicePrice} RSD/mes`;
                    // });
                }

                if (price !== '1' && deviceType === '7') {
                    $('#placanje-rate-mobile').click();
                }

                qs('.m-full-modal.js-modal.is-on', openedModal => {
                    openedModal.classList.remove('is-on');
                });


                const tariffs_that_should_not_have_insurance_string = tariffs_that_should_not_have_insurance || ''; // from bottom_scripts.html

                const tariffsThatShouldNotHaveInsurance = tariffs_that_should_not_have_insurance_string.split(',');

                qs('.insurance-close-button-clickable', insuranceButtonWrapper => {
                    if (tariffsThatShouldNotHaveInsurance.includes(tariffCodeNew)) {
                        insuranceButtonWrapper.classList.add('display_none')
                    } else {
                        insuranceButtonWrapper.classList.remove('display_none')
                    }
                })

                /**
                 * Ako je cena jedan dinar za odabranu tarifu  sakriva se radio button za placanje na rate
                 *
                 * else vraca placanje na rate
                 *
                 * Pozivamo cashInstalmentsSwitch da bi se reload-ovali podaci na sticky-u
                 */
                if (cleanPrice(price) === 1 || cleanPrice(price) < cleanPrice(minPriceForActivation)) {
                } else {
                }
                //console.log(tariffCodeNew,' <--------tariffCodeNew');
                //change to switch

                switch (tariffCodeNew) {
                    case 'BPO_NEOPLATINUM_P':
                        $('#old_price_promo').addClass('display_none')
                        $('#platinumPromo').removeClass('display_none')
                        $('#insurancePromo').addClass('display_none')
                        //$('#Neo24Promo').addClass('display_none')
                        $('#Spring24Promo').addClass('display_none')
                        //$('.neo24-disclaimer-pdp').addClass('display_none')
                        $('#neo24PdpTooltip').addClass('display_none')
                        $('.platinum-tariff-promo-text-pdp-wrapper').removeClass('display_none')
                    break;
                    case 'BPO_NEO24_P':
                        $('#old_price_promo').addClass('display_none')
                        // spring 24 promo for some devices:
                        // console.log(deviceCode,'<----------deviceCode')
                        // console.log(spring24Devices,'<----------spring24Devices')
                        if (spring24Devices.includes(deviceCode)) {
                            $('#Spring24Promo').removeClass('display_none')
                        }
                        //$('.neo24-disclaimer-pdp').removeClass('display_none')
                        $('#neo24PdpTooltip').removeClass('display_none')
                        $('#platinumPromo').addClass('display_none')
                        $('#insurancePromo').addClass('display_none')
                        $('#old_price_cash_promo').addClass('display_none')
                        $('.platinum-tariff-promo-text-pdp-wrapper').addClass('display_none')
                        break;
                    case 'STANDALONE':
                        $('.discount15-toggler').addClass('display_none');
                        if (window.isDiscount15k && !window.location.href.includes("poslovni")) {
                            $('#toggleDiscount').click();
                        }
                        break;
                    default:
                        $('.discount15-toggler').removeClass('display_none');
                        $('#platinumPromo').addClass('display_none')
                        //$('#Neo24Promo').addClass('display_none')
                        $('#Spring24Promo').addClass('display_none')
                        //$('.neo24-disclaimer-pdp').addClass('display_none')
                        $('#neo24PdpTooltip').addClass('display_none')
                        if($('.old_price_cash_promo').hasClass('display_none')){
                            $('#insurancePromo').removeClass('display_none')

                        }
                        $('.platinum-tariff-promo-text-pdp-wrapper').addClass('display_none')
                    break;
                }


                setDLPDP();
                // Neo23 Feb 2023
                /*if(tariffCodeNew === 'BPO_NEO23_P') {
                    $('#neo23PdpTooltip').removeClass('display_none')
                } else {
                    $('#neo23PdpTooltip').addClass('display_none')
                }*/

                //setDLPDP();
                qs('body', el => {
                    el.style.overflow = '';
                });
                if (e.currentTarget.tagName === 'A') {
                    e.preventDefault();
                } else {
                    if(document.querySelector('.o-slide-up-down__item-detail.tariffs-radio-button-view').classList.contains('expanded')){
                        simulateClick(document.querySelector('.tariff-section-close-button-clickable'));
                    }
                }
            };
        });
    };

    changeBTSValuesAboveSticky = (tariffOldPrice, price) => {
        const pdpvc = new PDPVirtualCart();
        const { tariff } = pdpvc.getAll();
        const { name } = tariff;
        qs('.old-price-promo', el => {
            el.innerHTML = `${tariffOldPrice} RSD`;
        })
        qs('.price-promo', el => {
            el.innerHTML = `${price} RSD`;
        });
        qs('.promo-tariff-name', el => {
            el.innerHTML = name;
        });
    }

    getSelectedPayment = () => this._selectedPayment;

    _createTariffPropEl = (prop) => createElementFromHTML(`
        <div class="m-04-text-with-icon">
            <img src="https://cdn.a1.rs/a1website/img/a1/icons/icon-check-black.svg" alt="Check mark" width="14px" height="10px">
            <span class="a-01-text__primary--sm ">${prop}</span>
        </div>
    `);

    addInsuranceEvent = (e) => {
        const pdpvc = new PDPVirtualCart();
        const { setTotal, handleCloseInsurance } = this;
        const bpo = e.currentTarget.getAttribute('data-promo-id');
        const participation = e.currentTarget.getAttribute('data-participation');
        const monthly = e.currentTarget.getAttribute('data-monthly');
        const name = e.currentTarget.getAttribute('data-name');
        const isPromotionActive = e.currentTarget.getAttribute('data-is-promotion-active');



        let payloadForInsuranceInStore = null;

        let insuranceNewStateValue = monthly;
        if (pdpvc.insurance.isSelected()) {
            payloadForInsuranceInStore = {
                ...pdpvc.insurance.getAll(),
                isSelected: false,
            };
            pdpvc.clearInsurance();
            insuranceNewStateValue = 0;
        } else {
            pdpvc.insurance.setAll(bpo, name, monthly, participation, isPromotionActive);
            pdpvc.insurance.toggleSelected();

            payloadForInsuranceInStore = {
                insuranceName: name,
                insuranceParticipation: participation,
                insurancePrice: monthly,
                insuranceType: bpo,
                insurancePromotionActive: isPromotionActive,
                isSelected: true,
            };
        }
        store.dispatch('setInsurance', {insurance: payloadForInsuranceInStore});


        // qs('.device-insurance-montly-price-selected', insurancePriceLabel => {
        //     insurancePriceLabel.innerHTML = `${insuranceNewStateValue}`;
        // });
        this.state.insuranceMonthlyFee = cleanPrice(insuranceNewStateValue);
        qs('.insurance-add-button', button => {
            this.toggleButton(button, this._languageConsts['addInsurance'], this._languageConsts['remove']);
        });


        qs('.selected-insurance', selectedInsurance => {
            if(selectedInsurance.classList.contains('display_none')) {
                handleCloseInsurance()
            }
            selectedInsurance.classList.toggle('display_none');
        });

        qs('.not-selected-insurance', selectedInsurance => {
            selectedInsurance.classList.toggle('display_none');
        });

        qs('#insurance-drawer', insuranceModalEl => {
            insuranceModalEl.classList.remove('is-on');
        });
        e.preventDefault();
    }

    createLoaderSpinnerWithText = ({ text }) => {
        return `<div class="cp_spinner_wrapper">
            ${text ? text : ''}<span class="cp_spinner cp_round"></span>
        </div>`
    }

    // handleContinueToShopLoader = () => {
    //
    // }

    getMmidsForAllProductsPdp = () => {
        const { device, gadgets } = tmpVc.getAll();
        const { accessories } = store.state
        const { deviceMmid, deviceCode, deviceType } = device;
        const { accId } = accessories
        let mmids = []



        for(let i = 0; i < accessories.length; i++) {
            if(accessories[i].code !== undefined) {
                mmids.push(accessories[i].code)
            }
        }

        if (deviceType === "1" || deviceType === "3" || deviceType === "6") {
            mmids.push("SPO_"+deviceMmid)
        }
        if (deviceType === "7") {
            mmids.push("TRDHW_"+deviceMmid)
        }
        if(deviceType === "8" || deviceType === "2") {
            mmids.push(deviceCode)
        }
        //console.log('mmids', mmids);

        return mmids
    }

    checkAvailabilityPdp = (mmids) => {

        window.tmpMmids = mmids
        window.tmpMmidsForModal = []

        let apiUrl = '';
        if (IS_LOCALHOST) {
            apiUrl = 'http://vlxa1website.topnet.rs'; // on pp we check production
        } else if (IS_PP_SERVER) {
            apiUrl = 'http://vlxa1website.topnet.rs'; // on pp we check production
        }
        return $.ajax({
            type: 'POST',
            url: `${apiUrl}/api/check-available-mmids`,
            data: JSON.stringify({
                "mmids": mmids.filter(mmid => !!mmid),
            }),
            dataType: 'json',
        })
            .done(function (data) {

                let debug = false;


                let mmids = window.tmpMmids
                let mmidsStockInside = []
                for(let j = 0; j < data.mmids_stock.length; j++) {
                    let item1 = data.mmids_stock[j].MMID
                    mmidsStockInside.push(item1)
                }

                window.tmpMmidsForModal = []
                for(let i = 0; i < mmids.length; i++) {
                    let item = mmids[i]
                    if(mmidsStockInside.includes(item)) {
                    } else {
                        window.tmpMmidsForModal.push(item)
                    }
                }

                if(data.mmids_stock.length !== 0) {
                    return "MMID_IN_STOCK"
                } else {
                    return "MMID_NOT_IN_STOCK"
                }
            })
            .fail(function (e) {
                return "MMID_FAILED"
            })

    }

    handleInsurance = (insurancePriceElClass, monthly, insuranceAddButton) => {


        // document.querySelectorAll('.not-selected-insurance')[1].onclick = function(e) {
        //     e.preventDefault();
        //     e.stopPropagation();
        // }

        qs('.not-selected-insurance', nsiEl => {
            nsiEl.onclick = (e) => {
                e.preventDefault();
                e.stopPropagation();
            };
        });

        qs('.open-insurance-modal', openInsModalEl => {
            openInsModalEl.onclick = (e) => {
                const insModal = document.querySelector('#insurance-drawer');
                if (insModal) {
                    insModal.classList.add('is-on');
                }
                e.preventDefault();
            };
        });

        qs(`.${insurancePriceElClass}`, insurancePriceEl => {
            insurancePriceEl.innerHTML = monthly;
        });

        qs(`.${insuranceAddButton}`, insuranceAddButtonSingle => {
            insuranceAddButtonSingle.onclick = this.addInsuranceEvent;
        });

    };

    getNotAvailableGadgets = (mmids_stock) => {
        const notAvailableGadgets = [];
        const { accessories } = store.state
        if (accessories.length > 0) {
            accessories.forEach(accessory => {
                if(accessory.deviceType === 8 || accessory.deviceType === '8') {
                    const accessoryCheck = mmids_stock.filter(stock => stock.MMID === accessory.code);
                    if (accessoryCheck.length === 0) {
                        notAvailableGadgets.push(accessory.code);
                    }
                }
            });
        }
        return notAvailableGadgets;
    }

    getNotAvailableAccessories = (mmids_stock) => {
        const { accessories } = store.state
        const notAvailableAccessories = [];
        if (accessories.length > 0) {
            accessories.forEach(accessory => {
                if(accessory.deviceType === 2 || accessory.deviceType === '2') {
                    const accessoryCheck = mmids_stock.filter(stock => stock.MMID === accessory.code);
                    if (accessoryCheck.length === 0) {
                        notAvailableAccessories.push(accessory.code);
                    }
                }
            });
        }
        return notAvailableAccessories;
    }

    isDeviceAvailable = (mmids_stock) => {
        const { device: mainDevice } = tmpVc.getAll();
        if (mainDevice) {
            const fieldToCheck = mainDevice.deviceType === '7' ? 'MMID' : 'DevType';
            const deviceCheck = mmids_stock.filter(stock => stock[fieldToCheck] === mainDevice.deviceCode);
            if (deviceCheck.length === 0) {
                return false;
            }
        }
        return true;
    }

    generateLangConstForNotAvailableModal = (deviceNotInStock, accessoriesNotInStock, gadgetsNotInStock, notAllowedWithTariff) => {
        let notAvailableProductsString = deviceNotInStock ? 'device' : '';

        if (accessoriesNotInStock) {
            if (notAvailableProductsString) {
                notAvailableProductsString += '_';
            }
            notAvailableProductsString += 'accessory';
        }

        if (gadgetsNotInStock) {
            if (notAvailableProductsString) {
                notAvailableProductsString += '_';
            }
            notAvailableProductsString += 'gadget';
        }
        if (notAllowedWithTariff) {
            if (notAvailableProductsString) {
                notAvailableProductsString += '_';
            }
            notAvailableProductsString += 'not_allowed';
        }


        return `${notAvailableProductsString}_not_available_lang_const`;
    }

    goToCart = () => {
        const pdpvc = new PDPVirtualCart();
        const { _isActive, _selectedPayment } = this;
        if (window.hasOwnProperty('virtualCart')) {
            if (!_isActive) {
                const { device, gadgets  } = pdpvc.getAll();
                const { tariff, participation, insurance, accessories, paymentType } = store.state;

                /**
                 * This if will happen if PDP is for accessory
                 */
                if (this._isAccessory && accessories && Array.isArray(accessories) && tariff) {
                    const vcTariffCode = virtualCart?.tariff?.tariffCode; // eslint-disable-line no-undef
                    if (this._isGadget) {
                        gadgets.forEach((accessory) => {
                            virtualCart.appendToGadget(accessory?.accessoryId, accessory?.accessoryCode, accessory?.accessoryType, accessory?.accessoryComercialName, accessory?.accessoryManufacturerName,  accessory?.accessoryName, accessory?.accessoryPrice, accessory?.accessoryImage, participation); // eslint-disable-line no-undef
                        });
                    } else {
                        accessories.forEach((accessory) => {
                            // virtualCart.appendToAccessories(accessory?.accessoryId, accessory?.accessoryCode, accessory?.accessoryType, accessory?.accessoryComercialName, accessory?.accessoryManufacturerName,  accessory?.accessoryName, accessory?.accessoryPrice, accessory?.accessoryImage, participation); // eslint-disable-line no-undef
                            virtualCart.appendToAccessoriesN({ // eslint-disable-line no-undef
                                accId: accessory?.accId,
                                code: accessory?.code,
                                type: accessory?.accessoryType,
                                commercialName: accessory?.commercialName,
                                manufacturerName: accessory?.manufacturerName,
                                name: accessory?.name,
                                price: accessory?.price,
                                image: accessory?.image,
                                priceCash: accessory?.priceCash,
                                participation,
                            });
                        });
                    }

                    if (!vcTariffCode) {
                        // virtualCart.setTariff(tariff?.tariffCode, tariff?.price, tariff?.priceCash, tariff?.externalId, tariff?.tariffName, tariff?.name, tariff?.monthlyFee, tariff?.tariffMontlyFeeWithoutDevice, tariff?.tariffProperties); // eslint-disable-line no-undef
                    }
                } else {
                    /**
                     * This will happen if PDP is for mobile device (default)
                     */
                    if (device && tariff) {
                        if (device.deviceType === '3') {
                            const splitedName = device?.deviceName.split(' ');
                            const manufactuter = splitedName[0];
                            const name = device?.deviceName.replace(`${manufactuter} `, '');
                            virtualCart.setPreselectedHW({
                                code: device.deviceCode,
                                price: tariff?.price,
                                name: name,
                                manufacturer: manufactuter,
                            });
                        } else {
                            virtualCart.setDevice(device?.deviceType, device?.deviceCode, device?.deviceManufacturer, device?.deviceName, device?.deviceColor, device?.deviceMmid, device?.devicePriceCash, this._instalments, device?.deviceParticipation, device?.deviceMonthlyFee, device.deviceImage); // eslint-disable-line no-undef
                        }
                        virtualCart.setTariffNew({
                            tariffCode: tariff?.tariffCode,
                            price: tariff?.price,
                            priceCash: tariff?.priceCash,
                            externalId: tariff?.externalId,
                            tariffName: tariff?.tariffName,
                            name: tariff?.name,
                            monthlyFee: tariff?.monthlyFee,
                            monthlyFeeWithoutDevice: tariff?.tariffMontlyFeeWithoutDevice,
                            properties: tariff?.tariffProperties,
                            participation: tariff?.participation,
                            minParticipationForActivation: tariff?.minParticipationForActivation,
                            maxParticipationForActivation: tariff?.maxParticipationForActivation,
                            oldMonthlyDevicePrice:  tariff?.oldMonthlyDevicePrice,
                            tariffCollection: tariff?.tariffCollection,
                            tariffOldPrice: tariff?.tariffOldPrice,
                            tariffOldPriceCash: tariff?.tariffOldPriceCash,
                            tariffMonthlyPriceOld: tariff?.tariffMonthlyPriceOld,
                            tariffMonthlyDevicePriceOld: tariff?.tariffMonthlyDevicePriceOld,
                            shortDescription: tariff?.shortDescription,
                            shortDescriptionWithDevice: tariff?.shortDescriptionWithDevice,
                            shortDescriptionWithoutDevice: tariff?.shortDescriptionWithoutDevice,
                        })
                        // virtualCart.setTariff(tariff?.tariffCode, tariff?.price, tariff?.priceCash, tariff?.externalId, tariff?.tariffName, tariff?.name, tariff?.monthlyFee, tariff?.tariffMontlyFeeWithoutDevice, tariff?.tariffProperties, null, null, null, tariff?.oldMonthlyDevicePrice); // eslint-disable-line no-undef

                    } else {
                        // todo: handle no device or tariff (validation)
                    }

                    if (accessories && Array.isArray(accessories)) {
                        accessories.forEach((accessory) => {
                            if (accessory.deviceType === "8") {
                                virtualCart.appendToGadgetsN({ // eslint-disable-line no-undef
                                    accId: accessory?.accId,
                                    code: accessory?.code,
                                    type: accessory?.accessoryType,
                                    commercialName: accessory?.commercialName,
                                    manufacturerName: accessory?.manufacturerName,
                                    name: accessory?.name,
                                    price: accessory?.price,
                                    image: accessory?.image,
                                    priceCash: accessory?.priceCash,
                                });
                            } else {
                                virtualCart.appendToAccessoriesN({ // eslint-disable-line no-undef
                                    accId: accessory?.accId,
                                    code: accessory?.code,
                                    type: accessory?.accessoryType,
                                    commercialName: accessory?.commercialName,
                                    manufacturerName: accessory?.manufacturerName,
                                    name: accessory?.name,
                                    price: accessory?.price,
                                    image: accessory?.image,
                                    priceCash: accessory?.priceCash,
                                });
                            }

                        });
                    }

                    if (insurance) {
                        virtualCart.setInsurance1({
                            type: insurance?.insuranceType,
                            name: insurance?.insuranceName,
                            price: insurance?.insurancePrice,
                            participation: insurance?.insuranceParticipation,
                            isSelected: pdpvc.insurance.isSelected(),
                            insurancePromotionActive: insurance?.insurancePromotionActive,
                        });
                        // virtualCart.setInsurance(insurance?.insuranceType, insurance?.insuranceName, insurance?.insurancePrice, insurance?.insuranceParticipation, pdpvc.insurance.isSelected()); // eslint-disable-line no-undef
                    }
                    virtualCart.setParticipation(participation); // eslint-disable-line no-undef
                }
                virtualCart.setPaymentType(paymentType); // eslint-disable-line no-undef

                virtualCart.setBinding(24);
                virtualCart.setAll(); // eslint-disable-line no-undef
                const { device: mainDevice } = virtualCart.getAll(); // eslint-disable-line no-undef
                const { deviceCode } = mainDevice;
                document.location.href = `/privatni/cart?device_code=${deviceCode}`;
                // e.currentTarget.href = `/privatni/cart?device_code=${deviceCode}`;
            }

        }
    }

    handleAddToCart = (cartButtonClass) => {
        /*if(XMASS_2022) {
            $('#tariffs-item-BPO_NEO20_P').attr('data-name', 'NEO 20')
        }*/

        $('#tariffs-item-BPO_NEO24_P').attr('data-name', 'NEO24')

        if (!window.hasOwnProperty('virtualCart')) {
            return;
        }
        const pdpvc = new PDPVirtualCart();

        qs(`.${cartButtonClass}`, addToCartButton => {
            addToCartButton.onclick = (e) => {

                if (!$(e.currentTarget).attr('data-device-code').startsWith('AD_')) {
                    virtualCart.process = '';
                }

                /*if (virtualCart.insurance.isSelected) {
                    virtualCart._insurance.toggleSelected();
                }*/

                const pdpvc = new PDPVirtualCart();

                let mmids = this.getMmidsForAllProductsPdp()
                window.loader = false

                $('.add-to-cart').addClass('loading');
                $('.add-to-cart').attr('disabled', true);

                $('.add-to-cart .a-02-button__label').html(
                    `<div class="cp_spinner_wrapper">
                    ${LC['PLEASE_WAIT_TEXT']}
                    <span class="cp_spinner cp_round"></span>
                </div>`
                )

                const noAccGadgetsTariffsString = no_acc_gadgets_tariffs;

                const noAccGadgetsTariffs = noAccGadgetsTariffsString.split(',');

                let noAddToCartWithThisTariff = false;
                if (noAccGadgetsTariffs.includes(virtualCart?.tariff?.tariffCode) && $(e.currentTarget).attr('data-device-code').startsWith('AD_')) {
                    noAddToCartWithThisTariff = true;
                }


                // const shouldCheckAvailability = false;
                if (SHOULD_CHECK_AVAILABILITY || noAddToCartWithThisTariff) {

                    this.checkAvailabilityPdp(mmids).then(response => {


                        let addedMmidsNotInStock = window.tmpMmidsForModal

                        if(addedMmidsNotInStock.length === 0 && !noAddToCartWithThisTariff) {
                            this.goToCart()
                        } else {
                            const lc = window.checkAvailabilityLc


                            let isAllAvailable = true;
                            const isDeviceAvailableInStock = this.isDeviceAvailable(response.mmids_stock);
                            const notAvailableAccessories = this.getNotAvailableAccessories(response.mmids_stock);
                            const notAvailableGadgets = this.getNotAvailableGadgets(response.mmids_stock);

                            const { accessories } = store.state

                            let miniTemplate = ``
                            if (!isDeviceAvailableInStock && tmpVc._device._deviceType !== '2') {
                                miniTemplate = `<p class="notAvailableAccessory">${tmpVc._device._deviceName}</p>`;
                            }
                            addedMmidsNotInStock = new Set(addedMmidsNotInStock);
                            addedMmidsNotInStock = [...addedMmidsNotInStock];
                            for (let i = 0; i < addedMmidsNotInStock.length; i++) {
                                let item = addedMmidsNotInStock[i]
                                for (let j = 0; j < accessories.length; j++) {
                                    let item1 = accessories[j]
                                    if(item === item1.code) {
                                        miniTemplate += `<p class="notAvailableAccessory">${item1.commercialName}</p>`
                                    }
                                }

                            }

                            let isAllNotAvailable = response.mmids_stock.length === 0;

                            if (notAvailableGadgets.length > 0 || notAvailableAccessories.length > 0 || !isDeviceAvailableInStock) {
                                isAllAvailable = false;
                            }

                            let isAllNotAvailableTxt =isAllNotAvailable ? LC['NOT_IN_STOCK_CLEAR_CART'] : LC['NOT_IN_STOCK'];

                            if (noAddToCartWithThisTariff) {
                                isAllNotAvailableTxt = LC['NOT_ALLOWED_ACC_GADGET'];
                                isAllNotAvailableTxt = `<p class="notAvailableAccessory">${isAllNotAvailableTxt}</p>`;
                                isAllNotAvailable = true
                                isAllAvailable = false;
                                miniTemplate='';

                            }

                            let notAvailableDeviceHTML = '';
                            let notAvailableProductsString = this.generateLangConstForNotAvailableModal(!isDeviceAvailableInStock, notAvailableAccessories.length > 0, notAvailableGadgets.length > 0,noAddToCartWithThisTariff);
                            //console.log(isAllNotAvailableTxt,'<---------------isAllNotAvailableTxt')

                            if(!isAllAvailable) {

                                if(isAllNotAvailable) {
                                    notAvailableProductsString += '_all_products_not_available';

                                }

                                notAvailableProductsString = LC[notAvailableProductsString] || notAvailableProductsString;

                            }


                            function handleContinueToCart(goToCart) {

                                if (!isDeviceAvailableInStock || isAllNotAvailable) {
                                    notAvailableAccessories.forEach(accessory => {
                                        store.dispatch('removeAccessory', {code: accessory});
                                    })
                                    notAvailableGadgets.forEach(accessory => {
                                        store.dispatch('removeAccessory', {code: accessory});
                                    })
                                    location.href = LC[`PLP_LINK_${tmpVc._device.deviceType}`];
                                    // console.log(LC[`PLP_LINK_${tmpVc._device.deviceType}`], 'LINK ZA PLP')
                                    // location.reload();
                                } else {
                                    if (notAvailableAccessories.length > 0) {
                                        notAvailableAccessories.forEach(accessory => {
                                            store.dispatch('removeAccessory', {code: accessory});
                                        })
                                    }
                                    if (notAvailableGadgets.length > 0) {
                                        notAvailableGadgets.forEach(accessory => {
                                            store.dispatch('removeAccessory', {code: accessory});
                                        })
                                    }
                                    if(goToCart) {
                                        pdpHandler.goToCart()
                                    }
                                }
                            }

                            let modal = `
                            <div id="pdp_availability_modal"  class="m-modal js-modal is-on">
                                <div class="overlay "></div>
                                <div class="m-modal__content-area">
                                    <div class="m-modal__type-icon ">
                                        <img src="https://cdn.a1.rs/a1website/img/a1/modals/icon-warning-red.svg" alt="Close modal" width="29" height="29">
                                    </div>
                                    <div class="m-modal__title">
                                        <div class="a-01-text__secondary-bold--3xl  ">${notAvailableProductsString}</div>
                                    </div>
                                    <div class="m-modal__content">
                                        <div class="p-shopping-cart-warning">
                                            <div class="p-shopping-cart-warning__desc">
                                                <div class="notAvailableAccessories">
                                                    <span class="a-01-text__primary--base lh-large">${isAllNotAvailableTxt}</span>
                                                    ${miniTemplate}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-modal__action-buttons">
                
                                            <button class="a-02-button__inverted--primary has-label modal-abort-handler-class ${isAllNotAvailable ? 'display_none' : ''}" type="button">
                                                <span class="a-02-button__label ">Odustani</span>
                                                <span class="a-02-button__icon ">
                                                     <img inline-svg="" src="https://cdn.a1.rs/a1website/img/a1/icons/icon-chevron-right-white.svg">
                                                 </span>
                                            </button>
                                    
                                        
                                            <button class="a-02-button__flat--primary has-label modal-continue-handler-class" type="button">
                                                <span class="a-02-button__label ">Nastavi</span>
                                                <span class="a-02-button__icon ">
                                                     <img inline-svg="" src="https://cdn.a1.rs/a1website/img/a1/icons/icon-chevron-right-white.svg">
                                                 </span>
                                            </button>
                                    
                                    </div>
                                </div>
                            </div>
                        `

                            $('body').append(modal)
                            $('.modal-continue-handler-class').on('click', function() {
                                handleContinueToCart(true)
                            })
                            $('.modal-abort-handler-class').on('click', function() {
                                handleContinueToCart(false)
                                location.reload()
                            })
                            // alert('modal')
                        }
                    })
                } else {
                    pdpHandler.goToCart()
                }


                e.preventDefault();

            }
        });
    };

    setRangeMinAndMax = (el) => {
        // if (this._isAccessory) {
        //     return;
        // }
        // const pdpvc = new PDPVirtualCart();
        // const { tariff } = pdpvc.getAll();
        // const { participation: p, price, minParticipationForActivation, maxParticipationForActivation } = tariff;
        // const currentParticipation = pdpvc.getParticipation();
        //
        // let participation = p;
        // if (!participation) {
        //     participation = 0;
        // }
        //
        // if (currentParticipation > cleanPrice(participation)) {
        //     participation = currentParticipation
        // }
        //
        // const intPrice = cleanPrice(price);
        //
        // if (this._isAccessory) {
        //     const minValueForRange = 0;
        //     const maxValueForRange = intPrice - 2400;
        //     el.min = `${minValueForRange}`;
        //     el.max = `${maxValueForRange}`;
        //     el.value = `${minValueForRange}`;
        // } else {
        //     const maxValueForRange = intPrice - minParticipationForActivation;
        //     let minValueForRange = intPrice - maxParticipationForActivation;
        //     if (maxParticipationForActivation > intPrice) {
        //         minValueForRange = 0;
        //     }
        //
        //
        //     let monthlyDevice = 0;
        //     let participationDevice = 0;
        //     monthlyDevice = Math.round((intPrice - participation) / this._instalments);
        //     participationDevice = minValueForRange;
        //     this.reRenderSticky({
        //         participationDevice,
        //         monthlyDevice
        //     });
        //
        //     el.min = `${minValueForRange}`;
        //     el.max = `${maxValueForRange}`;
        //     el.value = `${minValueForRange}`;
        // }
    }

    reRenderSticky = ({ monthlyDevice, participationDevice }) => {
        if (monthlyDevice || monthlyDevice === 0) {
            // qs('.rata-iznos', el => {
            //     el.innerHTML = `${monthlyDevice} RSD`;
            // });
        }
        if (participationDevice || participationDevice === 0) {
            qs('.ucesce-iznos', el => {
                el.innerHTML = `${participationDevice}`;
            });
        }

    }
    reRenderAndSetValuesForRange = (val) => {
        if (this._isAccessory) {
            if (this._isGadget) {
                const currentGadget = this.getCurrentGadget();
                if (currentGadget) {
                    currentGadget.accessoryParticipation = val;
                }
            } else {
                const currentAccessory = this.getCurrentAccessory();
                if (currentAccessory) {
                    currentAccessory.accessoryParticipation = val;
                }
            }

        }

    }

    rangeInputChangeHandler = (e) => {
        this.reRenderAndSetValuesForRange(e.currentTarget.value);
    }

    handleInitialRangeInput = (el) => {
        const { state, setRangeMinAndMax } = this;
        const pdpvc = new PDPVirtualCart();
        const { tariff } = pdpvc.getAll();
        const { participation: p, price, maxParticipationForActivation } = tariff;

        const currentParticipation = pdpvc.getParticipation();
        const intPrice = cleanPrice(price);

        let participation = p;
        if (!participation) {
            participation = 0;
        }

        if (currentParticipation > cleanPrice(participation)) {
            participation = currentParticipation
        }
        const intParticipation = cleanPrice(participation);
        const newMonthlyDevicePrice = Math.round((intPrice - intParticipation) / pdpvc.instalments);
        state.deviceMonthlyFee = newMonthlyDevicePrice;
        state.deviceParticipation = intParticipation;

        if (!currentParticipation || currentParticipation < intParticipation) {
            pdpvc.setParticipation(intParticipation);
        }

        if (participation > maxParticipationForActivation) {
            pdpvc.setParticipation(maxParticipationForActivation);
        }

        // let minValueForRange = intPrice - maxParticipationForActivation;
        if (maxParticipationForActivation > intPrice) {  // eslint-disable-line no-undef
            // minValueForRange = 0;
        }

        setRangeMinAndMax(el);
        // qs('.rata-iznos', (rataIznosEl) => {
        //     rataIznosEl.innerHTML = `${numberThousandSeparator(newMonthlyDevicePrice)} RSD`; // eslint-disable-line no-undef
        // });
        // qs('.ucesce-iznos', (el) => {
        //     el.innerHTML = `${numberThousandSeparator(participation)}`; // eslint-disable-line no-undef
        // });
    }

    handleParticipation = () => {
        qs('[name="ucesce"]', (el) => {
            // this.handleInitialRangeInput(el);
            const isJsInstance = el.getAttribute('data-js-instance');
            if (!isJsInstance) {
                el.setAttribute('data-js-instance', '1');
                el.addEventListener("input", e => {
                    store.dispatch('setParticipation', parseInt(e.currentTarget.value));
                });
            }
        });
    };

    setDeviceData = (type, code, manufacturer, name, price, participation, montlyFee, image) => {
        const pdpvc = new PDPVirtualCart();
        const { color, mmid } = pdpvc.getDeviceColorData();
        pdpvc.setDevice(type, code, manufacturer, name, color, mmid, price, this._instalments, participation, montlyFee, image)
    };

    handleMobileScrollToBasket = () => {
        qs('.mobile-basket-scroll-to', mobileBasketScrollToButton => {
            mobileBasketScrollToButton.onclick = e => {
                const mobileBasket = document.querySelector('.t-pdp__mobile-basket');
                const y = mobileBasket?.getBoundingClientRect()?.top + window.scrollY;
                if (y) {
                    window.scroll({
                        top: y,
                        behavior: 'smooth'
                    });
                }
                e.preventDefault();
            };
        });
    }

    handlePreorder = () => {
        const pdpvc = new PDPVirtualCart();
        qs('.preorder-button', preorderButton => {
            preorderButton.onclick = e => {
                const webshopUrl = e.currentTarget.href;
                const { device, tariff, installments } = pdpvc.getAll();
                const { deviceCode, deviceMmid } = device;
                const { tariffCode } = tariff;

                let preorderTariffCode = tariffCode;

                const deviceInstalments = $('#select-rate option:selected').val();
                const installmentsUpfrontElement = $('.ucesce-iznos-desktop-section').text().trim();
                const installmentsUpfrontElementNew = installmentsUpfrontElement.replace('.','');
                let installmentsUpfront = '';


                if((device.deviceType !== "8" || device.deviceType !== "2") && $('[name="placanje"]:checked').val() === 'rate' && installmentsUpfrontElementNew !== '') {
                    installmentsUpfront = installmentsUpfrontElementNew;
                }

                // console.log(installmentsUpfront, "installmentsUpfront")

                if((device.deviceType === "8" || device.deviceType === "2") && deviceInstalments && $('[name="placanje"]:checked').val() === 'rate') {
                    preorderTariffCode = 'ACCINSTALL';
                }

                let linkForPreorder = `https://a1.rs/webshopfe/details?dev_type=${deviceCode}&mmid=${deviceMmid}&tariff=${preorderTariffCode}&process[]=preorder`;
                if (IS_TEST_SERVER) {
                    linkForPreorder = `${webshopUrl}?dev_type=${deviceCode}&mmid=${deviceMmid}&tariff=${preorderTariffCode}&process[]=preorder`;
                }
                // e.currentTarget.href = linkForPreorder;

                const pricesForAll = this.getPricesForEveryItemOnPDP();
                $.ajax({
                    type: 'POST',
                    url: '/api/set-cart',
                    data: {
                        preset_json: JSON.stringify(pricesForAll),
                    },
                    dataType: 'json',
                    // timeout: 13000
                })
                  .done(function (data) {
                      if (data?.cart_id) {
                          linkForPreorder += '&cart_id=' + data.cart_id;
                      }

                      const deviceInstalments = $('#select-rate option:selected').val();
                      if (deviceInstalments && $('[name="placanje"]:checked').val() === 'rate') {
                          linkForPreorder += '&installments_number=' + deviceInstalments;
                      }
                      if(installmentsUpfront !== '' && $('[name="placanje"]:checked').val() === 'rate') {
                          linkForPreorder += '&installments_upfront=' + installmentsUpfront;
                      }
                      // e.currentTarget.href = linkForPreorder;
                      window.location.href = linkForPreorder;
                  })
                  .fail(function (e) {
                      console.log('fail', e);
                      // $('#main-msg').text(LC['SYSTEM_ERROR']);
                  })
                e.preventDefault();
            }

        });
    }

    getPricesForEveryItemOnPDP = () => {
        const allPrices = {
            device: null,
            accessoriesAndGadgets: null,
            insurance: null,
            tariff: null,
            tariffAddons: null
        };
        allPrices.device = this.getPriceForDevice();
        allPrices.accessoriesAndGadgets = this.getPricesForAccessoriesAndGadgets();
        allPrices.tariff = this.getTariffDataFromCart();
        return allPrices;
    }

    getTariffDataFromCart = () => {
        const { tariff, device } = tmpVc.getAll();
        if (tariff?.tariffCode && tariff?.tariffCode !== 'STANDALONE') {
            const { monthlyFee, tariffCode, name, tariffMontlyFeeWithoutDevice, oldMonthlyDevicePrice } = tariff;
            return {
                priceMonthly: device?.deviceCode ? cleanPrice(monthlyFee) : null,
                priceMonthlyWithoutDevice: device?.deviceCode ? null : cleanPrice(tariffMontlyFeeWithoutDevice),
                oldPriceMonthly: cleanPrice(oldMonthlyDevicePrice),
                code: tariffCode,
                name,
                properties: tariff.tariffProperties
            }
        }
        return null;
    }

    getPricesForAccessoriesAndGadgets = () => {
        const accessoryPrices = this.getAccessoryPricesList();
        const gadgetsPrices = this.getGadgetsPricesList();

        if (!accessoryPrices && !gadgetsPrices) {
            return null;
        }
        if (!accessoryPrices && gadgetsPrices) {
            return gadgetsPrices;
        }
        if (accessoryPrices && !gadgetsPrices) {
            return accessoryPrices;
        }
        return { ...accessoryPrices, ...gadgetsPrices };
    }

    getGadgetsPricesList = () => {
        const { gadgets } = tmpVc.getAll();

        if (!gadgets || gadgets?.length === 0) {
            return null;
        }
        return gadgets.reduce((accumulator, currentValue) => {
            return {
                ...accumulator,
                [currentValue.accessoryCode]: {
                    price: cleanPrice(currentValue.accessoryPrice),
                    oldPrice: null,
                    participation: cleanPrice(currentValue.accessoryParticipation),
                }
            }
        }, {});
    }


    getAccessoryPricesList = () => {
        const { accessories } = tmpVc.getAll();

        if (!accessories || accessories?.length === 0) {
            return null;
        }
        return accessories.reduce((accumulator, currentValue) => {
            return {
                ...accumulator,
                [currentValue.accessoryCode]: {
                    price: cleanPrice(currentValue.accessoryPrice),
                    oldPrice: null,
                    participation: cleanPrice(currentValue.accessoryParticipation),
                }
            }
        }, {});
    }

    getPriceForDevice = () => {
        const { tariffCode } = tmpVc.getTariff();

        if (tariffCode && tmpVc.getDevice()?.deviceCode) { // we have tariff code even if it is standalone - standalone is also tariff :)
            const { price, priceCash, oldPrice, oldPriceCash } =  tmpVc.getTariff();

            return {
                price: cleanPrice(price),
                priceCash: cleanPrice(priceCash),
                oldPrice: oldPrice ? cleanPrice(oldPrice) : null,
                oldPriceCash: oldPriceCash ? cleanPrice(oldPriceCash) : null,
                // participation: cleanPrice(this._participation),
            }
        }
        return null;
    }



    closeBasketSection = (copyElClass, originalElClass) => {
        qs(`.${copyElClass}`, (el) => {
            el.onclick = () => {
                qs(`.${originalElClass}`,elClickable => {
                    simulateClick(elClickable);
                });
            }
        });
    }

    closeAccessoriesBasket = () => {
        qs('.tariff-accessories-close-button-clickable', elClickable => {
            simulateClick(elClickable);

        });
    }


    handleCloseBasketTariffSection = () =>
        this.closeBasketSection('basket-tariff-section-close-button', 'tariff-section-close-button-clickable');

    handleClosePaymentTypeSection = () =>
        this.closeBasketSection('basket-payment-type-section-close-button', 'tariff-payment-type-close-button-clickable');

    handleCloseAccessories = () =>
        this.closeBasketSection('basket-accessories-section-close-button', 'tariff-accessories-close-button-clickable');

    handleCloseInsurance = () =>
        this.closeBasketSection('basket-insurance-section-close-button', 'insurance-close-button-clickable');

    setInstallments = installments => this._instalments = installments;


    setTotal = () => {
        // const { isPrice1RSD } = this.state;
        const { totalMonthlyPrice, totalParticipation } = virtualCart.getTotal(this.state); // eslint-disable-line no-undef
        const { tariff } = virtualCart.getAll();  // eslint-disable-line no-undef
        const {  price } = tariff;
        let totalParticipationFinal = totalParticipation;
        if (totalParticipation === 0 && cleanPrice(price) === 1) {
            totalParticipationFinal = 1;
        }


        qs('.monthly-summary', el => {
            el.innerHTML = `${numberThousandSeparator(Math.round(totalMonthlyPrice))} RSD`; // eslint-disable-line no-undef
        });

        qs('.participation-total', el => {
            el.innerHTML = `${numberThousandSeparator(Math.round(totalParticipationFinal))} RSD`; // eslint-disable-line no-undef
        });
    }

    finalize = ({ tariffOldPrice }) => {
        const pdpvc = new PDPVirtualCart();
        const { tariff, insurance, device } = pdpvc.getAll();
        const { participation } = tariff;


        store.dispatch('setTariff', {...tariff, tariffOldPrice, deviceType:device.deviceType});
        store.dispatch('setPaymentType', this._selectedPayment);
        store.dispatch('setInstalments', this._instalments);
        store.dispatch('setIsTvOrTable', {isTvOrTablet: this._isTvOrTablet});
        store.dispatch('setInsurance', {insurance});
        store.dispatch('setIsBusiness', {isBusiness: this._isBusiness});
        store.dispatch('setParticipation', cleanPrice(participation));
        store.dispatch('setIsBusinessAndTablet', {isBusinessAndTablet: this._isBusinessAndTablet})


        store.dispatch('setTariffCommunicationPromoList', {tariffCommunicationPromoList: this._tariffCommunicationPromoList})

        if (this._isAccessory) {
            const currentAccessory = this.getCurrentAccessory();
            store.dispatch('appendAccessory', {
                accId: currentAccessory?.accessoryId,
                code: currentAccessory?.accessoryCode,
                commercialName: currentAccessory?.accessoryComercialName,
                manufacturerName: currentAccessory?.accessoryManufacturerName,
                name: currentAccessory?.accessoryName,
                price: currentAccessory?.accessoryPrice,
                priceCash: currentAccessory?.priceCash,
                image: currentAccessory?.accessoryImage,
                participation: currentAccessory?.accessoryParticipation,
            });

            store.dispatch('setIsAccessory', {isAccessory: this._isAccessory});
        }



// Instantiate components
//         const listInstance = new List();
        const stickyWrapper = new Wrapper('.sticky-basket-desktop');
        const monthlyTariffPriceInstance = new MonthlyTariffPrice();
        const tariffNameInstance = new TariffName();
        const tariffPropertiesInstance = new TariffProperties();
        // const monthlyDevicePriceInstance = new MonthlyDevicePrice('.device-price-tp-price-desktop');
        const devicePriceInstance = new DevicePrice('.device-price-tp-price-desktop');
        const monthlyDevicePriceInstance1 = new MonthlyDevicePrice('.rata-iznos-desktop');
        const stickyPromoHeaderInstance = new StickyPromoHeader();
        const participationDeviceInstance = new ParticipationDevice('.ucesce-iznos-desktop');
        const participationDeviceSectionInstance = new ParticipationDevice('.ucesce-iznos-desktop-section');
        const paymentTypeLabelInstance = new PaymentTypeLabel();
        const totalMonthlyInstance = new TotalMonthly();
        const totalParticipationInstance = new TotalParticipation();
        const insurancePriceMonthlyInstance = new InsurancePriceMonthly('.device-insurance-montly-price-desktop');
        const insurancePriceMonthlyInstanceNoDiscount = new InsurancePriceMonthlyNoDiscount('.device-insurance-montly-price-desktop-no-discount');
        const insurancePriceMonthlyInstanceNoDiscount1 = new InsurancePriceMonthlyNoDiscount('.device-insurance-montly-price-desktop-no-discount-1');

        const insurancePriceMonthlyInstance1 = new InsurancePriceMonthly('.device-insurance-montly-price-selected-desktop');
        const insurancePriceMonthlyInstanceModal = new InsurancePriceMonthly('.device-insurance-montly-price-modal');

        const participationRangeInputInstance = new ParticipationRangeInput();
        const accessoriesAllPriceInstance = new AccessoriesAllPrice();
        const accessoriesListInstance = new AccessoriesList();
        const accessoriesCountInstance = new AccessoriesCount();
        const tariffCommunicationPromoInstance = new TariffCommunicationPromo({
            languageConsts: this._languageConsts
        })

        //device-insurance-montly-price-desktop
        //device-insurance-montly-price-selected-desktop
// Initial renders
//         listInstance.render();

        stickyWrapper.render();
        monthlyTariffPriceInstance.render();
        tariffNameInstance.render();
        tariffPropertiesInstance.render();
        devicePriceInstance.render();
        stickyPromoHeaderInstance.render();
        monthlyDevicePriceInstance1.render();
        participationDeviceInstance.render();
        participationDeviceSectionInstance.render();
        paymentTypeLabelInstance.render();
        totalMonthlyInstance.render();
        totalParticipationInstance.render();
        insurancePriceMonthlyInstance.render();
        insurancePriceMonthlyInstanceNoDiscount.render();
        insurancePriceMonthlyInstanceNoDiscount1.render();
        insurancePriceMonthlyInstance1.render();
        insurancePriceMonthlyInstanceModal.render();
        participationRangeInputInstance.render();
        accessoriesAllPriceInstance.render();
        accessoriesListInstance.render();
        accessoriesCountInstance.render();
        tariffCommunicationPromoInstance.render();

        qs('.not-selected-accessories-desktop', el => {
            el.onclick = (e) => {
                e.preventDefault();
                e.stopPropagation();
            }
        });
        if (virtualCart.getPaymentType() === 'gotovina') {
            qs('#placanje-gotovina-mobile', el => {
                simulateClick(el);
            })

        }
    }

}

export default PDPHandler;
